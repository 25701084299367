<template>
    <component :is="UserContentTemplate" />
</template>

<script setup>
/**
 * Bu bileşen, menünün sağ tarafında yer alan kullanıcı içerik alanını içermektedir.
 * Hesabım, Sepetim vb.
 **/

const storeConfig = useStoreConfig()
const { themeComponents } = storeToRefs(storeConfig)

const UserContentTemplate = shallowRef();
switch(unref(themeComponents)?.navigation_right_user_area_component) {
  case '2':
    UserContentTemplate.value = resolveComponent('LazyTemplatesNavigationRightSide2')
    break;
  case '3':
    UserContentTemplate.value = resolveComponent('LazyTemplatesNavigationRightSide3')
    break;
  case '4':
    UserContentTemplate.value = resolveComponent('LazyTemplatesNavigationRightSide4')
    break;
  default :
    UserContentTemplate.value = resolveComponent('LazyTemplatesNavigationRightSide1')
}
</script>